.container {
  background-color: #151515;
  width: 100vw;
  height: 100vh;

  color: #eee;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 64px;
  color: yellow;
}

.time-text-primary {
  font-size: 78px;
  color: white;
}

.subtext {
  margin-top: 48px;
  font-size: 32px;
  color: yellow;
}

.time-text {
  font-size: 36px;
}